import React from 'react';

import { Helmet } from 'react-helmet';

import Header from 'components/common/header';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import { PageContainer, SectionTitle, ContentText, BoldText, SectionSubTitle } from './styles';

const meta = PageMeta.getStatic('terms_and_conditions');

const TermsAndConditions = () => {
	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header searchable />
			<TitleBar title="Terms And Conditions" />
			<PageContainer>
				<SectionTitle>DigsConnect Terms of Use</SectionTitle>
				<ContentText>Applicable to the user of the DigsConnect platform</ContentText>
				<ContentText>("the DigsConnect Platform")</ContentText>
				<SectionSubTitle>Preamble</SectionSubTitle>
				<ContentText>
					The terms and conditions set forth below apply to your use of the DigsConnect Platform and
					by using the DigsConnect Platform to receive services from DigsConnect you agree to be
					bound by these terms and conditions.
				</ContentText>
				<ContentText>
					The DigsConnect Platform may only be used in accordance with these terms and conditions.
				</ContentText>
				<ContentText>
					For your convenience, certain terms and phrases have been defined, and you will find those
					defined terms and phrases at the end of these terms and conditions.
				</ContentText>
				<ContentText>
					Please pay specific attention to the <BoldText>BOLD</BoldText> paragraphs of these
					DigsConnect Terms. These paragraphs limit the risk or liability of DigsConnect, constitute
					an assumption of risk or liability by you, impose an obligation by you to indemnify
					DigsConnect or is an acknowledgement of any fact by you.
				</ContentText>
				<ContentText>
					Users explicitly understand and agree that DigsConnect and the Platform operate as a
					platform-as-a-service marketplace and facilitation and advertisement platform only, where
					<BoldText>
						{' '}
						any formal engagement or negotiation relating to the leasing of a property is
						exclusively the responsibility and liability of the Landlord and Tenant.
					</BoldText>
				</ContentText>

				<SectionSubTitle>Use of the DigsConnect Platform</SectionSubTitle>
				<ContentText>
					DigsConnect grants you the right to use and enjoy the DigsConnect Platform anywhere in the
					world, on a mobile device or internet-enabled device that you own or control, in
					accordance with these terms and conditions.
				</ContentText>
				<ContentText>
					The right to use and enjoy the DigsConnect Platform is not granted exclusively to you. It
					is granted to you for your personal use only, and you will not be entitled to transfer or
					sublicense the use and enjoyment of the DigsConnect Platform in any way, either in whole
					or in part, to any other person.
				</ContentText>
				<ContentText>
					You may not, nor may you attempt to, modify, decompile, disassemble or otherwise reverse
					engineer the DigsConnect Platform.
				</ContentText>
				<ContentText>
					Save for the limited rights provided to you in terms of these terms and conditions, you
					acquire no other rights whatsoever in the DigsConnect Platform.
				</ContentText>
				<ContentText>
					The DigsConnect Platform, its software and operating systems, trademarks, copyrights,
					database of data and other intellectual property rights that relate to the DigsConnect
					Platform are owned by DigsConnect and/or by such licensor thereof as DigsConnect has
					received rights under license. The intellectual property will never become owned by you.
				</ContentText>
				<ContentText>
					<BoldText>
						When you use the DigsConnect Platform, you consent to receive communications from
						DigsConnect, in whatever form DigsConnect deems appropriate, including by telephone,
						short messaging services, electronic communication platforms and electronic mail.
					</BoldText>{' '}
					Communication with our service team will be primarily conducted through WhatsApp or call,
					and by using our services, you expressly consent to this mode of communication.
				</ContentText>

				<ContentText>
					You acknowledge that in order to use the DigsConnect Platform you will be required to
					complete DigsConnect's online Profile application in respect of either Landlords or
					Tenants. You may only hold one Profile and that Profile may only be used by you for your
					own account.
				</ContentText>
				<ContentText>
					You must notify DigsConnect immediately should any information which is recorded in your
					Profile change and you warrant that the information will remain accurate for the duration
					of your access to or use of the DigsConnect Platform.
				</ContentText>
				<ContentText>
					You acknowledge that you are responsible for all activity and resultant costs on your
					Profile and you agree to maintain the security and secrecy of your Profile username and
					password at all times. We will not be liable in any way for any loss or harm you suffer as
					a result of a breach of your responsibility to maintain the security and secrecy of these
					details.
				</ContentText>
				<ContentText>
					Whilst DigsConnect tries to manage the Platform and items thereon, we are not responsible
					for the complete verification or information detailed of any listings and/or locations
					made available by Landlords or our partners. Users must confirm all such information about
					a location/listing with the relevant third-party provider when engaging with them,
					including with our listed academic partners that we work with.
				</ContentText>
				<ContentText>
					DigsConnect is in no way affiliated with the universities (where universities, just like
					conventional Landlords, remain independent user-Landlords on the Platform) unless
					explicitly specified otherwise on the Platform or through our advertising, where this
					relationship is not exclusive at any time.
				</ContentText>
				<ContentText>
					DigsConnect will have the right in its sole and unfettered discretion to suspend or
					terminate your Profile, in which event your right to use the DigsConnect Platform will be
					suspended or terminated, as the case may be.
				</ContentText>
				<ContentText>
					DigsConnect undertakes to process and store your information contained on your Profile in
					accordance with the law and will take reasonable steps to secure and protect your personal
					information.
				</ContentText>
				<ContentText>
					You acknowledge and consent to your User Data being disclosed to Landlords, Tenants or any
					third party for the purposes of managing the letting and/or leasing of the Leased
					Property, providing related services or for any other lawful purpose.
				</ContentText>
				<ContentText>
					You also acknowledge and consent to DigsConnect disclosing your User Data to any insurer,
					applicable regulator or other interested parties in the event that there is any complaint,
					dispute, conflict, loss, damage, death or injury arising from your use of the DigsConnect
					Platform.
				</ContentText>
				<ContentText>
					When using the DigsConnect Platform, you may be presented with the option to voluntarily
					opt in to receive communications from third-party partners, including financial services partners,
					regarding student loan products and related offers. By opting in, you explicitly consent
					to your contact information being shared with these third parties for the purposes of
					receiving such communications.
				</ContentText>
				<ContentText>
					DigsConnect will only share your information with these partners upon your explicit
					consent.
				</ContentText>
				<ContentText>
					You retain the right to withdraw your consent at any time by updating your preferences on
					the DigsConnect Platform or contacting DigsConnect support.
				</ContentText>
				<ContentText>
					DigsConnect is not responsible for any third-party products, services, or communications,
					and all interactions with such third parties are subject to their terms and conditions and
					privacy policies.
				</ContentText>

				<SectionSubTitle>Platform And Service Functionality</SectionSubTitle>
				<ContentText>
					General service detail: The DigsConnect Platform enables Users to upload personal
					information, to advertise or view advertisements for the letting of residential
					properties, to arrange viewings of residential properties, to interact with other Users
					for the purposes concluding a Lease Agreement and other services related to the leasing of
					residential property.
				</ContentText>
				<ContentText>
					All property descriptions must adhere to our specified format for consistency and accuracy
					and you acknowledge that DigsConnect reserves the right to edit listing descriptions to
					meet our formatting requirements.
				</ContentText>
				<ContentText>
					Specific service detail: The Platform allows users to 'apply' for a specific property to
					lease, after which DigsConnect will send the applying user's information directly to that
					listed property's Landlord, in order for that Landlord to get in touch with the applicant
					user.
				</ContentText>
				<ContentText>
					Whilst a Tenant's use of the Platform and Services are free, in consideration for the use
					of the Platform and DigsConnect services,{' '}
					<BoldText>
						Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R800
						for leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant.
						Rates are exclusive of VAT.
					</BoldText>
				</ContentText>
				<ContentText>
					Any complaints or queries relating to the DigsConnect Platform can be sent to
					DigsConnect's support team, which you can contact via the DigsConnect Platform.
				</ContentText>

				<SectionSubTitle>Booking Process</SectionSubTitle>
				<ContentText>The Tenant makes a free Booking enquiry; and then;</ContentText>
				<ContentText>The Landlord replies to their connection request;</ContentText>
				<ContentText>
					Chats directly with the Tenant through the internal messaging system on the Platform, and;
				</ContentText>
				<ContentText>
					Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R800 for
					leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant. Rates
					are exclusive of VAT.
				</ContentText>
				<ContentText>
					Where multiple Tenants make a single Booking enquiry to a Landlord or a Tenant makes a
					Booking enquiry to a Landlord after which they introduce and/or otherwise include other
					Tenants in the Booking, the Landlord will be invoiced for each Tenant placed with the
					Landlord under such Booking.
				</ContentText>
				<ContentText>
					<BoldText>
						The Facilitation Fee paid by a Landlord must be paid once a Booking enquiry has been
						confirmed by a Tenant, and is subject to the DigsConnect Cancellation Policy. Invoicing
						occurs upon confirmation of the deal, and payment is due within 14 days or invoice
						unless a written agreement for alternative arrangements has been established. Payment
						extensions will only be considered for trusted landlords who have at least one prior
						paid invoice on record.
					</BoldText>
				</ContentText>

				<SectionSubTitle>Cancellation Policy</SectionSubTitle>
				<ContentText>
					If a cancellation occurs within the first 3 calendar months, a credit will be issued for
					the portion of the Facilitation Fee for which the tenant did not stay. You acknowledge,
					however, that communication of the cancellation must be shared with DigsConnect within 3
					calendar months of the move in date.
				</ContentText>
				<ContentText>
					<BoldText>
						In the event of a deal cancellation or the need for invoice adjustments, valid proof
						must be provided for processing.
					</BoldText>
				</ContentText>

				<SectionSubTitle>Further Conditions Of Service </SectionSubTitle>
				<ContentText>
					Landlords expressly understand and agree that they are exclusively responsible for
					identifying, understanding, and complying with all applicable laws, rules and regulations
					that apply to making their properties and private services available on the DigsConnect
					Platform and to private Tenants.
				</ContentText>
				<ContentText>
					If you as Tenant or Landlord have any queries or confusion about how or which laws may
					apply to your supply or receipt of such services from other users, you should always seek
					legal guidance.
				</ContentText>
				<ContentText>
					<BoldText>
						All users understand and explicitly consent to DigsConnect running verification checks
						on the data they submit to DigsConnect in use of their Services and Platform, in order
						for DigsConnect to verify the information supplied as being true and correct in every
						claimed way, for the safety and security of all users of our Services. These
						verifications are only undertaken with authorised verification providers, where same
						processing is strictly subject to all applicable South African data laws, as well as our
						comprehensive Privacy Policy.{' '}
					</BoldText>
				</ContentText>

				<SectionSubTitle>No Circumvention Of Services And Platform</SectionSubTitle>
				<ContentText>
					DigsConnect expends great effort and time in ensuring that all users of the platform act
					as they are required and according to our strict terms, for the benefit and safety of all
					parties. This ensures that users have as safe, secure and transparent service-experience
					as possible. If users try to operate outside of the terms and prescripts of the
					Services/Platform, they may experience a range of unpredictable and undesirable results
					for which DigsConnect will accept no liability.
				</ContentText>
				<ContentText>
					Accordingly, for the benefit of all users and the integrity of the DigsConnect Services
					and Platform, all users expressly understand and agree that no user may circumvent or
					attempt to circumvent DigsConnect and the Services by providing their own equitable or
					other related services with the negligent or purposeful intention to bypass DigsConnect
					and/or the Services for a user's private benefit to the detriment of DigsConnect. This
					includes Landlords and Tenants contacting each other or contracting with each other
					outside of the prescribed processes and allowances required by the Services/Platform.{' '}
				</ContentText>
				<ContentText>
					Should any user be found, or reasonably be suspected, to be operating as specifically
					prohibited in these terms, including the prohibited circumvention detailed above, that
					action immediately constitutes an automatic material breach of these terms by the relevant
					user, allowing DigsConnect to manifest its full range of legal rights against the
					infringing user, including suing the infringing user for direct and consequential damages
					suffered due to the prohibited circumvention.
				</ContentText>

				<SectionSubTitle>Specific User Warranties</SectionSubTitle>
				<ContentText>
					When using the Platform and/or Services, all users explicitly warrant that they will:
				</ContentText>
				<ContentText>
					not engage in offensive, violent, sexual, disturbing or other inappropriate behaviour on
					the Platform or in any engagement between users;
				</ContentText>
				<ContentText>
					report any theft or criminal conduct perpetrated against them to the relevant authorities
					and DigsConnect (where you understand and agree that DigsConnect is under no obligation to
					perform any further action in this regard);
				</ContentText>
				<ContentText>
					not at any time whilst using the Services and/or Platform, circumvent or attempt to
					circumvent DigsConnect and the Services by providing their own equitable or other related
					services with the negligent or purposeful intention to bypass DigsConnect and/or the
					Services for a user's private benefit to the detriment of DigsConnect;
				</ContentText>
				<ContentText>
					use the Platform and DigsConnect services on the basis of understanding that DigsConnect
					is not an estate agent or Landlord in any way, where all liability and responsibility
					relating to the lease relationship between Landlord and Tenant rests with those users
					exclusively;
				</ContentText>
				<ContentText>
					not use the Platform or services for any commercial or in a way to otherwise mislead
					others as to your relationship with DigsConnect;
				</ContentText>
				<ContentText>
					not process any other user's personal information, derived from the Platform in any way
					without that user's express consent to do so, or that otherwise violates the DigsConnect
					Privacy Policy or the privacy rights of users or third parties;
				</ContentText>
				<ContentText>
					not use the Platform for any unauthorised use, such as for the facilitation of any illegal
					or undesirable activity, including illegal practices, transactions or the distribution of
					unsolicited marketing (i.e. spam marketing);
				</ContentText>
				<ContentText>
					not offer or host any user at their property as Landlord, where same right to host of
					offer is not lawfully theirs to provide;
				</ContentText>
				<ContentText>
					not contact another user for any purpose other than asking a question related to a
					relevant property, their own Booking enquiry/listing, or the other user's use of the
					Platform (including prohibited engagements related, but not limited to, recruiting or
					otherwise soliciting any users to join third-party services, applications or websites);
				</ContentText>
				<ContentText>
					not use the Platform to circumvent DigsConnect in trying, requesting, making or accepting
					a Booking enquiry outside and exclusive of the Platform, to DigsConnect's detriment;
				</ContentText>
				<ContentText>
					not discriminate against or harass anyone on the basis of race, national origin, religion,
					gender, gender identity, physical or mental disability, medical condition, marital status,
					age or sexual orientation, or otherwise engage in o any violent, harmful, abusive or
					disruptive behaviour;
				</ContentText>
				<ContentText>
					not use DigsConnect's intellectual property without DigsConnect's prior express written
					permission;
				</ContentText>
				<ContentText>
					always engage with DigsConnect and other users in good faith and for the mutual benefit of
					all involved parties as far as possible;
				</ContentText>
				<ContentText>
					not use any automated means or processes to collect or process any restricted information
					which they may have access to via the Platform, or attempt to avoid, bypass, remove,
					deactivate, impair, descramble, or otherwise attempt to circumvent any technological
					measure implemented by DigsConnect or any of DigsConnect's providers or any other third
					party to protect the Platform;
				</ContentText>
				<ContentText>
					not perform any act that can or does damage or negatively affect the performance or proper
					functioning of the Platform in any way; and not violate or infringe anyone else's rights
					or otherwise cause harm to anyone.
				</ContentText>

				<SectionSubTitle>Additional Services</SectionSubTitle>
				<ContentText>
					DigsConnect will prescribe a fee payable by the Landlord to DigsConnect for any additional
					services, which will be paid into DigsConnect's prescribed banking account.
				</ContentText>

				<SectionSubTitle>Third-party Links To Platforms</SectionSubTitle>
				<ContentText>
					To assist Tenants in finding suitable accommodation, DigsConnect has helpful software
					interfaces with some tertiary institutions' websites (institutions with whom we have an
					authorised application interface), where if a Tenant clicks 'I need off-campus housing' on
					that institution's website, the Tenant automatically gets a DigsConnect account, which
					they shall be notified of via email, and will have various suitable properties suggested
					to them by DigsConnect.
				</ContentText>
				<ContentText>
					Users may then choose to retain or reject that DigsConnect account, where if accepting,
					the user will then need to accept DigsConnect's various terms and policies and will be
					deemed to then expressly understand and consent to their personal information being
					processed by DigsConnect as attained from the relevant institution.
				</ContentText>

				<SectionSubTitle>Advertising</SectionSubTitle>
				<ContentText>
					The Platform may contain advertising and sponsorship. Advertisers and sponsors are
					responsible for ensuring that material submitted for inclusion on the Platform complies
					with all applicable laws and regulations.
				</ContentText>
				<ContentText>
					DigsConnect, its shareholders, employees, suppliers, partners, affiliates and agents
					accordingly exclude, to the maximum extent permitted in law, any responsibility or
					liability for any error or inaccuracy appearing in advertising or sponsorship material.
				</ContentText>

				<SectionSubTitle>Disclaimer And Indemnification</SectionSubTitle>

				<ContentText>
					Nothing in these Terms and Conditions is intended to nor will it establish any
					relationship of partnership, joint venture, employment, agency or any similar relationship
					between DigsConnect and the Landlord or between DigsConnect and the Tenant.
				</ContentText>
				<ContentText>
					DigsConnect receives data and services from sources which are not connected to it, nor
					subject to its control. DigsConnect and the participants in its supply chain rely on
					internet connectivity and electronic communications, and are susceptible to all the
					vagaries inherent in that form of connectivity and communication. Whilst DigsConnect will
					take reasonable steps to create a stable and accessible electronic platform by way of the
					DigsConnect Platform, it does not represent or warrant, whether express or implied, that
					the platform or the data aggregated therein will be free of instability or error.
				</ContentText>
				<ContentText>
					Neither DigsConnect nor its proprietor or any of their officers or employees will be
					liable for any interruption or delay in the use of the DigsConnect Platform, any
					interception in communications or corruption of data (including User Data) or systems, any
					transfer of computer viruses, any error or misstatement in relation to any data (including
					User Data), any incompleteness in relation to data (including User Data), any improper or
					inapplicable use to which any data is put, any claim or loss of any nature relating to or
					arising from the download, installation, access to or use of the DigsConnect Platform or
					any data (including user data) aggregated within or processed by the DigsConnect Platform.
				</ContentText>
				<ContentText>
					To the fullest extent permitted by law, you waive any claim which you may have against
					DigsConnect, its officers and employees and you hold DigsConnect harmless and indemnify it
					against any such claim, including but not limited to reputational damage, bodily injury,
					death, theft, loss, damage to property (including direct, indirect, special or
					consequential damages), costs (including but not limited to legal costs) and claims
					arising from or related to your use of the DigsConnect Platform. DigsConnect will do its
					best to, and will take all reasonable steps to, monitor, flag and address suspicious
					and/or inappropriate behaviour.
				</ContentText>
				<ContentText>
					<BoldText>
						DigsConnect has no control over and does not guarantee (i) the existence, quality,
						safety, suitability, or legality of any Listings, (ii) the truth or accuracy of any
						Listing descriptions, Ratings, Reviews, or other User Content, or (iii) the performance
						or conduct of any User or third party. DigsConnect does not endorse any User or Listing.
						Any references to a User being "verified" (or similar language) only indicate that the
						Member has completed a relevant verification or identification process and nothing else.
						Any such description is not an endorsement, certification or guarantee by DigsConnect
						about any User or Landlord, including of the User's or Landlord's identity or background
						or whether the User or Landlord is trustworthy, safe or suitable. You should always
						exercise due diligence and care when deciding whether to stay in an Accommodation,
						communicate and interact with other Users or Landlords, whether online or in person.
						Images are intended only to indicate a photographic representation of a Listing at the
						time the photograph was taken, and are therefore not an endorsement by DigsConnect of
						any User or Landlord or Listing.
					</BoldText>
				</ContentText>

				<ContentText>
					<BoldText>DigsConnect is not, and explicitly does not operate as:</BoldText>
				</ContentText>

				<ContentText>
					1. an estate agent or property practitioner generally, or a "property practitioner" as
					defined and governed in the Property Practitioners Act, 2019, as amended, in particular;
					or
				</ContentText>
				<ContentText>2. an attorney, conveyancer, financial or tax advisor.</ContentText>
				<ContentText>
					DigsConnect has no power as an agent or otherwise to negotiate or conclude any agreement
					on any user's behalf, and no such power must be inferred. All users agree and understand
					that they are exclusively liable for all obligations and consequences howsoever arising
					from your negotiation or conclusion of any agreement pursuant to your lease arrangement as
					a Tenant or Landlord. You are strongly urged to make detailed enquiries about any party
					that you intend negotiating or contracting with, and to obtain advice from a qualified
					professional before concluding any contract.
				</ContentText>
				<ContentText>
					DigsConnect does not endorse or guarantee any third-party services, including student loan
					products offered by our financial services partners or any other partners. By opting in to be 
					contacted by these third parties, you acknowledge that DigsConnect is not liable for any claims, 
					disputes, or damages arising from such interactions. Users are encouraged to review the
					terms, conditions, and privacy policies of the third-party provider before engaging with
					them.
				</ContentText>

				<SectionSubTitle>General Provisions</SectionSubTitle>
				<ContentText>
					DigsConnect, or any party from whom it receives any element of the DigsConnect Platform,
					may from time to time improve, upgrade or enhance the DigsConnect Platform, in which
					event, the DigsConnect Platform thus improved or enhanced will be the subject of these
					terms and conditions.
				</ContentText>
				<ContentText>
					DigsConnect may from time to time activate temporary marketing campaigns for the
					DigsConnect Platform. In this event, additional terms and conditions will be disclosed to
					you and shall be deemed to be a part of these terms and conditions. The obligation is
					therefore on you to review these terms and conditions at regular intervals.
				</ContentText>
				<ContentText>
					DigsConnect will be entitled to amend any provision of these terms and conditions from
					time to time without notice to you. Any amendment to these terms and conditions shall be
					effective when uploaded by DigsConnect to its website and/or the DigsConnect Platform.
					Your continued access to or use of the DigsConnect Platform will be deemed to be your
					acceptance of these terms and conditions, as amended. It is important that you review
					these terms and conditions regularly so that you are aware of any amendments.
				</ContentText>
				<ContentText>
					The law of the Republic of South Africa will be used in the interpretation and enforcement
					of these terms and conditions.
				</ContentText>
				<ContentText>
					If any provision of these terms and conditions is found by a court to be invalid, void or
					unenforceable, the remaining provisions shall remain in full force and effect.
				</ContentText>
				<ContentText>
					No failure on the part of DigsConnect to exercise, and no delay by DigsConnect in
					exercising, any right under these terms and conditions will operate as a waiver of that
					right, nor will any single or partial exercise of any right under these terms and
					conditions preclude any further or other exercises of that right nor act to the exclusion
					of any other right.
				</ContentText>
				<ContentText>
					DigsConnect is in no way affiliated with the universities unless specified implicitly on
					the website/Platform or through our advertising.
				</ContentText>

				<SectionSubTitle>Definitions</SectionSubTitle>
				<ContentText>
					Additional Services: The provision of priority listings to Landlords, photographing leased
					premises for purposes of advertising on the DigsConnect Platform and any other additional
					services offered by DigsConnect from time to time.
				</ContentText>
				<ContentText>
					Confirmation of Tenancy: The term "Confirmation of Tenancy" shall refer to the
					acknowledgment or affirmation by either the Landlord or Tenant, whether written or verbal,
					that a tenancy agreement has been duly executed or that a rental deposit has been paid,
					whichever occurs first.
				</ContentText>
				<ContentText>
					DigsConnect: DigsConnect (Proprietary) Limited, a private company registered in the
					Republic of South Africa with registration no. 2017/040276/07.The DigsConnect Platform:
					The Landlord and Tenant facilitation mobile web-based computer application, software and
					other components.
				</ContentText>
				<ContentText>
					Facilitation Fee or Finder's Fee: The terms "Facilitation Fee" and "Finder's Fee" shall be
					used interchangeably to refer to the fee charged by DigsConnect for each tenant who
					secures accommodation through the DigsConnect platform, either directly or indirectly.
					This fee shall be due upon Confirmation of Tenancy and payable within 30 days of the
					issuance of an invoice.
				</ContentText>
				<ContentText>Landlord: Anyone listing accommodation on the platform.</ContentText>
				<ContentText>
					Lease Agreement: The agreement of lease concluded between the Landlord and the Tenant.
				</ContentText>
				<ContentText>
					Leased Premises: The property which is advertised on DigsConnect or the subject of the
					Lease Agreement as the context dictates.
				</ContentText>
				<ContentText>
					Profile: A profile allocated to each Landlord and Tenant by the DigsConnect Platform,
					which records the User's name, address, mobile phone number, age, preferred method of
					payment of the Rental and any further information which DigsConnect may require from time
					to time.
				</ContentText>
				<ContentText>
					Rental: The amount payable by the Tenant to the Landlord in terms of the Lease Agreement.
				</ContentText>
				<ContentText>Tenant: Any user looking for accommodation on the platform.</ContentText>
				<ContentText>User: A person who downloads and uses the DigsConnect Platform.</ContentText>
				<ContentText>
					User Data: Data pertaining to a User, including that recorded on its Profile, its
					identity, habits and preferences relating to the letting and leasing of property, payment
					methods, address and communication via the DigsConnect Platform.
				</ContentText>
			</PageContainer>
			<Footer />
		</Main>
	);
};

export default TermsAndConditions;
